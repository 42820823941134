import { Globals } from './Globals';

const URLTypeMapping = {
    intro: ['', ''],
    january: ['january', 'january'],
    february: ['february', 'february'],
    march: ['march', 'march'],
    april: ['april', 'april'],
    may: ['may', 'may'],
    june: ['june', 'june'],
    july: ['july', 'july'],
    august: ['august', 'august'],
    september: ['september', 'september'],
    october: ['october', 'october'],
    november: ['november', 'november'],
    december: ['december', 'december'],
    twentytwenty: ['', ''],
    sustainability: ['sustainability', 'sustainability'],
    progressive_education: ['progressive-education', 'progressive-education'],
    artificial_intelligence: ['artificial-intelligence', 'artificial-intelligence'],
    precision_health: ['precision-health', 'precision-health'],
    social_progress: ['social-progress', 'social-progress'],
}

const NextMonthMapping = {
    january: ['february', 'february'],
    february: ['march', 'march'],
    march: ['april', 'april'],
    april: ['may', 'may'],
    may: ['june', 'june'],
    june: ['july', 'july'],
    july: ['august', 'august'],
    august: ['september', 'september'],
    september: ['october', 'october'],
    october: ['november', 'november'],
    november: ['december', 'december'],
}

export function getPagePath(year: string, slug: string, type: "intro" | "january" | "february" | "march" | "april" | "may" | "june" | "july" | "august" | "september" | "october" | "november" | "december" | "twentytwenty" | "sustainability" | "progressive_education" | "artificial_intelligence" | "precision_health" | "social_progress", vertical?: string) {
    return `/${year}-year-in-review${type !== 'twentytwenty' ? '/' + Globals.CURRENT_LANGUAGE_PREFIX : ''}${URLTypeMapping[type][Globals.CURRENT_LANGUAGE_PREFIX === '' ? 0 : 1]}${vertical ? vertical + '/' : slug ? '/' : ''}${slug}`;
}

export function getNextPagePath(year: string, slug: string, type: any) {
	return `/${year}-year-in-review/${Globals.CURRENT_LANGUAGE_PREFIX}${NextMonthMapping[type][Globals.CURRENT_LANGUAGE_PREFIX === '' ? 0 : 1]}${slug ? '/' : ''}${slug}`;
}