import * as React from 'react';
import { Link } from 'gatsby';
import SelectLanguage from './SelectLanguage';
// @ts-ignore
import * as styles from './Header.module.scss';
import { injectIntl, FormattedMessage } from 'react-intl';
import { WrappedComponentProps } from 'react-intl';
import loadable from '@loadable/component'

const MinimizableWebChat = loadable(() => import('../lib/webchat/MinimizableWebChat'))
class Header extends React.Component<
	{
		langs: any[];
		alternateURL: string;
		currPageTitle: string;
		pageTitle?: string;
		location: any;
		bilingual?: boolean;
		currLangKey?: string;
        currYear: string;
	} & WrappedComponentProps
> {
    private selectYear = (value) => {
        var value = value.target.options[value.target.selectedIndex].value;
        window.location.href = '/' + value + '-year-in-review' + (this.props.langs[1].selected ? '/ar/' : '/');
    }

	render() {
		return (
			<div className={`${styles.wrapper}  ${this.props.currYear === '2023' && styles.backgroundLight}`}>
				<div className={`${styles.background}`} id="background" />
				<SelectLanguage
					isSearch={this.props.currPageTitle === 'Search' || this.props.currPageTitle === 'بحث' ? true : false}
					inMenu={true}
					alternateURL={this.props.alternateURL}
					langs={this.props.langs}
                    currYear={this.props.currYear}
				/>
				<div className={styles.fixedWrapper} id="fixedWrapper">
					<div className={styles.logoContainer}>
						<Link
							title={this.props.intl.formatMessage({ id: 'headerlogotitle' })}
							tabIndex={0}
							aria-label={this.props.intl.formatMessage({ id: 'headerlogoarialabel' })}
							className={`${styles.logo} ${this.props.currYear === '2023' && styles.logoAlign}`}
							to={this.props.langs[1].selected ? '/ar' : '/'}
						/>
						<h2 className={`${styles.heading} text-style-h2-alt`}>
                            <span><FormattedMessage id='qf_text' /></span>&nbsp;
                            {this.props.currYear}: <FormattedMessage id='annual_report' /></h2>
                        {
                            (
                                <select onChange={this.selectYear}>
                                    <option selected={this.props.currYear === '2023' ? true : false} value={'2023'}>2023</option>
                                    <option selected={this.props.currYear === '2022' ? true : false} value={'2022'}>2022</option>
                                    <option selected={this.props.currYear === '2021' ? true : false} value={'2021'}>2021</option>
                                    <option selected={this.props.currYear === '2020' ? true : false} value={'2020'}>2020</option>
                                </select>
                            )
                        }
					</div>
					{this.props.bilingual !== false && (
						<div className={styles.languageToggleWrapper}>
							<SelectLanguage
								isSearch={this.props.currPageTitle === 'Search' || this.props.currPageTitle === 'بحث' ? true : false}
								inMenu={false}
								alternateURL={this.props.alternateURL}
								langs={this.props.langs}
                                currYear={this.props.currYear}
							/>
						</div>
					)}
                    {
                        (this.props.currYear === '2022') && <MinimizableWebChat isHomePage={false} lang={this.props.langs[1].selected ? 'ar' : 'en'} />
                    }
				</div>
			</div>
		);
	}
}

export default injectIntl(Header);