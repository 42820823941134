import React from 'react';
import { Component } from 'react';
import Header from '../components/Header';
import { getCurrentLangKey } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { getPagePath } from '../utils/URLHelper';
import './loader.css';
//Styles:
import '../styles/index.scss';
import { Globals } from '../utils/Globals';
import { graphql, StaticQuery, Script, Link, PageProps } from 'gatsby';
import { ThemeProvider } from 'react-hook-theme';

const initialState = { showNotification: false, alternateUrl: '', title: 'Qatar Foundation', menuOpen: false, currentLocation: null };
type State = Readonly<typeof initialState>;
class TemplateWrapper extends Component<{ children: any; location: any; layoutData: PageProps<Queries.globalsQueryAndLayoutQuery> }, State> {
	readonly state: State = initialState;

	constructor(props) {
		super(props);

		this.toggleHamburger = this.toggleHamburger.bind(this)
	}

	componentDidMount() {
		const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

		if(isMobile) {
		}

        var coll = document.getElementsByClassName("collapseMenu");
        var i;

        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
            this.classList.toggle("show");
            });
        }

        if(this.props.pageContext.theme) {
            document.getElementsByClassName("collapseMenu")[0]?.classList.add('show')
        } else {
            document.getElementsByClassName("collapseMenu")[0]?.classList.remove('show')
        }
	}

	// toggles hamburger menu in and out when clicking on the hamburger
	private toggleHamburger (e) {
		this.setState({
			menuOpen: !this.state.menuOpen
		})
		const navbar = document.getElementById('sidebar');
		const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

		if(isMobile) {
			if(this.state.menuOpen) {
                navbar.classList.remove("showNav")
			} else {
				navbar.classList.add("showNav")
			}
		} else {
			navbar.classList.add("showNav")
		}
	}

	render() {
		let { children, location } = this.props;
		const url = location.pathname;
		const { langs, defaultLangKey } = this.props.layoutData['site'].siteMetadata.languages;
		const langsArray = langs.map(language => language.langKey);
		let currLangKey = getCurrentLangKey(langsArray, defaultLangKey, url);
		let currLangDir = currLangKey == 'en' ? 'ltr' : 'rtl';
		
        var pathArray = typeof window !== 'undefined' && window.location.pathname.split( '/' );
		if (pathArray[2] === 'ar') {
			currLangKey = 'ar'
			currLangDir = 'rtl'
		}
		//@ts-ignore:
		const pageTitle = this.props.pageContext.title;

		//Used in URLHelper.ts
		Globals.CURRENT_LANGUAGE_PREFIX = currLangKey === 'en' ? '' : 'ar/';

		let langsMenu = langs.map(function(language) {
			return {
				langKey: language.languageCode,
				selected: currLangKey === language.langKey,
				link: '/' + language.langPath
			};
		});

		const i18nMessages = require(`../data/messages/${currLangKey}`);
		const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { location: location }));

		return (
            <ThemeProvider
                options={{
                    theme: 'light',
                    save: true,
                }}
            >
                <IntlProvider locale={currLangKey} messages={i18nMessages}>
                    <Script
                        id="add-bodyclass-afterload"
                        dangerouslySetInnerHTML={{
                            __html: `if(document.readyState!=='loading'){document.querySelector('html').setAttribute('data-body-class','js')}else{document.addEventListener('DOMContentLoaded',function(){document.querySelector('html').setAttribute('data-body-class','js')})}`
                        }}
                    />
                    <Script
                        strategy="idle"
                        id="userway"
                        dangerouslySetInnerHTML={{
                            __html: `(function(d){var s = d.createElement("script");s.setAttribute("data-account", "kwC0P4jhFt");s.setAttribute("src", "https://accessibilityserver.org/widget.js");(d.body || d.head).appendChild(s);})(document)`
                        }}
                    />
                    <div>
                        <Header
                            langs={langsMenu}
                            currPageTitle={this.state.title}
                            pageTitle={pageTitle}
                            location={location}
                            alternateURL={this.props.pageContext.alternateLanguage}
                            currLangKey={currLangKey}
                            currYear={this.props.pageContext.currYear}
                        />
                        <div className="siteWrapper" id="siteWrapper">
                            <div className={`sidebar ${this.props.pageContext.currYear === '2023' && 'bgGray'}`} id={'sidebar'}>
                                <ul className={'monthList'}>
                                {
                                    // @ts-ignore
                                    <li className={(!this.props.pageContext.currMonth && !this.props.pageContext.theme) && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'intro')}><FormattedMessage id="Intro" /></Link>
                                    </li>
                                }
                                {
                                    this.props.pageContext.currYear === '2023' &&
                                    // @ts-ignore
                                    <li className={'collapseMenu'}>
                                        <div id='themesMenu' className='toggleList'><FormattedMessage id="Themes" /></div>
                                        <ul>
                                            <li className={(this.props.pageContext.theme && this.props.pageContext.theme === 'sustainability') && 'activeTeal'}><Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'sustainability')}><FormattedMessage id="Sustainability" /></Link></li>
                                            <li className={(this.props.pageContext.theme && this.props.pageContext.theme === 'progressive-education') && 'activePink'}><Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'progressive_education')}><FormattedMessage id="Progressive Education" /></Link></li>
                                            <li className={(this.props.pageContext.theme && this.props.pageContext.theme === 'artificial-intelligence') && 'activeBlue'}><Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'artificial_intelligence')}><FormattedMessage id="Artificial Intelligence" /></Link></li>
                                            <li className={(this.props.pageContext.theme && this.props.pageContext.theme === 'precision-health') && 'activeGreen'}><Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'precision_health')}><FormattedMessage id="Precision Health" /></Link></li>
                                            <li className={(this.props.pageContext.theme && this.props.pageContext.theme === 'social-progress') && 'activeYellow'}><Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'social_progress')}><FormattedMessage id="Social Progress" /></Link></li>
                                        </ul>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'January' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'january')}><FormattedMessage id="January" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore						
                                    <li className={this.props.pageContext.currMonth === 'February' && 'active'}>
                                    <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'february')}><FormattedMessage id="February" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'March' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'march')}><FormattedMessage id="March" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'April' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'april')}><FormattedMessage id="April" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'May' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'may')}><FormattedMessage id="May" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'June' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'june')}><FormattedMessage id="June" /></Link>
                                    </li>
                                }
                                {
                                    this.props.pageContext.currYear === '2023' ? (
                                        <li className={this.props.pageContext.currMonth === 'July' && 'active'}>
                                            <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'july')}><FormattedMessage id="july_august" /></Link>
                                        </li>
                                    ) : (
                                        <>
                                            <li className={this.props.pageContext.currMonth === 'July' && 'active'}>
                                                <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'july')}><FormattedMessage id="July" /></Link>
                                            </li>
                                            <li className={this.props.pageContext.currMonth === 'August' && 'active'}>
                                                <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'august')}><FormattedMessage id="August" /></Link>
                                            </li>
                                        </>
                                    )
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'September' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'september')}><FormattedMessage id="September" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'October' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'october')}><FormattedMessage id="October" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'November' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'november')}><FormattedMessage id="November" /></Link>
                                    </li>
                                }
                                {
                                    // @ts-ignore
                                    <li className={this.props.pageContext.currMonth === 'December' && 'active'}>
                                        <Link onClick={(e) => this.toggleHamburger(e)} to={getPagePath(this.props.pageContext.currYear, '', 'december')}><FormattedMessage id="December" /></Link>
                                    </li>
                                }
                                {
                                    <li className="toggle"><a href="#"><i className="fas fa-bars"></i></a></li>
                                }
                                </ul>
                                <a href='https://www.qf.org.qa' className='qfLink' target='_blank'>
                                    QF.ORG.QA
                                </a>
                                <div className={'socialLinks'}>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://twitter.com/QF`}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                            <svg className={styles.x} style={{ transform: 'scale(0.9)' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xmlSpace="preserve">
                                                <defs>
                                                </defs>
                                                <g style={{ stroke: 'none', strokeWidth: '0', strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: '10', fill: 'none', fillRule: 'nonzero', opacity: '1'}} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 0.219 2.882 l 34.748 46.461 L 0 87.118 h 7.87 l 30.614 -33.073 l 24.735 33.073 H 90 L 53.297 38.043 L 85.844 2.882 h -7.87 L 49.781 33.341 L 27.001 2.882 H 0.219 z M 11.793 8.679 h 12.303 L 78.425 81.32 H 66.122 L 11.793 8.679 z" style={{ stroke: 'none', strokeWidth: '1', strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: '10', fill: '#11362a', fillRule: 'nonzero', opacity: '1'}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                            </svg>
                                            `
                                        }}
                                    />
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.facebook.com/qatarfoundation`}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path></svg>
                                            `
                                        }}
                                    />
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.linkedin.com/company/qatarfoundation`}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"></path></svg>
                                            `
                                        }}
                                    />
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`http://instagram.com/qatarfoundation`}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"></path></svg>
                                            `
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="pageWrapper" data-swiftype-name="body" data-swiftype-type="text" data-swiftype-index="true">
                                {childrenWithProps}
                            </div>
                        </div>
                        {
                            <button className={`ham ${this.props.pageContext.currYear === '2023' && 'hamDark'} ${this.state.menuOpen ? 'showClose' : ''}`} onClick={(e) => this.toggleHamburger(e)}></button>
                        }
                    </div>
                </IntlProvider>
            </ThemeProvider>
		);
	}
}

export default props => (
	<StaticQuery
		query={graphql`
			query globalsQueryAndLayout {
				site {
					siteMetadata {
						siteUrl
						languages {
							defaultLangKey
							langs {
								langPath
								languageCode
								langKey
							}
						}
					}
				}
			}
		`}
		render={data => <TemplateWrapper layoutData={data as globalsQueryAndLayout} {...props} />}
	/>
);