// extracted by mini-css-extract-plugin
export var background = "Header-module--background--927e4";
export var backgroundLight = "Header-module--backgroundLight--a07ef";
export var fixedWrapper = "Header-module--fixedWrapper--05b5e";
export var heading = "Header-module--heading--46714";
export var languageToggleWrapper = "Header-module--languageToggleWrapper--d557c";
export var logo = "Header-module--logo--9b96a";
export var logoAlign = "Header-module--logoAlign--fc00d";
export var logoContainer = "Header-module--logoContainer--457bd";
export var navbar = "Header-module--navbar--15b8c";
export var toggleThemeWrapper = "Header-module--toggleThemeWrapper--57019";
export var wrapper = "Header-module--wrapper--bce18";