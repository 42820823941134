import * as React from 'react';
import * as Qs from 'qs';
// @ts-ignore
import * as styles from './SelectLanguage.module.scss';

interface Props {
	langs: Array<{ link: string; langKey: string; selected: boolean }>;
	alternateURL: string;
	inMenu: boolean;
	isSearch?: boolean;
    currYear: string;
}

function getRelativeURL(url: string) {
    var the_arr = url?.replace('://','').split('/');
    the_arr?.shift();
    return( '/'+the_arr?.join('/') );
}

const SelectLanguage = (props: Props) => {
	let url = props.alternateURL
    let twentytwenty = typeof document !== 'undefined' && document.body.classList.contains("twentytwenty")
    let lightmode = typeof document !== 'undefined' && document.body.classList.contains("light-mode")
    let color

    if(!lightmode && twentytwenty) {
        color = '#50e3c1'
    }
	if(props.isSearch) {
		url = props.alternateURL + '/#' + Qs.stringify(Qs.parse(window.location.hash.slice(1)), { encode: false })
	}
	return (
		<div className={`${props.inMenu ? styles.wrapperInMenu : styles.wrapper} ${props.currYear === '2023' && styles.darkBg}`}>
			{props.langs.map(lang => {
				if (!lang.selected) {
					return (
						<a style={{ color: color }} href={url && '/' + props.currYear + '-year-in-review' + getRelativeURL(url)} key={lang.langKey}>
							{lang.langKey === 'en-US' ? 'English' : 'العربية'}
						</a>
					);
				}
			})}
		</div>
	);
};

export default SelectLanguage;