module.exports = {
  qf_text: 'مؤسسة قطر',
  annual_report:  "استعراض عام",
  annual_report_capitalize:  "استعراض عام",
  Intro: "مقدّمة",
  January: "يناير",
  February: "فبراير",
  March: "مارس",
  April: "أبريل",
  May: "مايو",
  June: "يونيو",
  July: "يوليو",
  August: "أغسطس",
  September: "سبتمبر",
  October: "أكتوبر",
  November: "نوفمبر",
  December: "ديسمبر",
  july_august: "يوليو/أغسطس",
  'Next chapter': "الفصل التالي",
  'Next': "Next",
  darkmode: 'الوضع المعتم',
  lightmode: 'الوضع العادي',
  'Themes': 'مواضيع',
  'About the annual report': 'حول التقرير السنوي',
  'Listen to the report': 'للاستماع إلى التقرير ',
  'Turn off audio': 'لإيقاف الصوت',
  'Accessibility Widget': 'إمكانية الوصول',
  'about_year_in_review': 'نبذة عن تقرير "استعراض عام"',
  'about_year_in_review_content': 'استحضارًا لالتزام مؤسسة قطر بقضايا الابتكار وتيسير الوصول والاستدامة، يأتي التقرير التفاعلي لمؤسسة قطر، "استعراض عام"، ليسلط الضوء على تجليات 12 شهرًا في حياة المؤسسة ومنظومتها الفريدة من نوعها.\n\n  يرصد تقرير "مؤسسة قطر 2022: استعراض عام"، بشكل يراعي التسلسل الزمني، الإنجازات والمبادرات والمحطات البارزة ومعالم التأثير في التعليم والبحوث والابتكار وتنمية المجتمع التي ميّزت السنة السالفة الذكر، مستعينًا في ذلك بعدة رسومات بيانية ومنشورات وسائل التواصل الاجتماعي وعناصر متعددة الوسائط لجعل هذه الأحداث والفعاليات تنبض بالحياة.\n\n  نتمنى أن تستمتعوا بقراءة تقرير "مؤسسة قطر 2022: استعراض عام"، وإذا كنتم ترغبون في معرفة المزيد عن مؤسسة قطر، فإننا ندعوكم إلى زيارة موقعنا الإلكتروني:',
  'Scroll to explore': 'مررْ للأسفل لتستكشف المزيد',
  'Scroll to read full report': 'حرك أصبعك عبر الشاشة لقراءة التقرير كاملًا',
  'explore themes': 'أو لاستكشافه حسب المواضيع',
  'Sustainability': 'الاستدامة',
  'Progressive Education': 'التعليم التقدمي',
  'Artificial Intelligence': 'الذكاء الاصطناعي',
  'Precision Health': 'الرعاية الصحية الدقيقة',
  'Social Progress': 'التقدم الاجتماعي',
  'sustainability': 'الاستدامة',
  'progressive-education': 'التعليم التقدمي',
  'artificial-intelligence': 'الذكاء الاصطناعي',
  'precision-health': 'الرعاية الصحية الدقيقة',
  'social-progress': 'التقدم الاجتماعي',
  'January-description': "في مؤسسة قطر، يأتي العام الجديد حاملًا معه فرصًا جديدة للتعلم والابتكار والاكتشاف والتعاون، ضمن بيئة معرفية لا مثيل لها في العالم.",
  'February-description': "أظهر مجتمع مؤسسة قطر تعاضده وتعاطفه مع ما كان يمر به من أوقات عصيبة ملايين الأشخاص في دول أخرى؛ وتزامن ذلك مع تعزيز مؤسسة قطر الفرص الرياضية الشاملة، ونقل التعلم من الفصول الدراسية إلى المدينة.",
  'March-description': "مؤسسة قطر تواصل جهودها الرائدة لتعزيز حضور لغة الضاد في العالم ودعم حلول الاستدامة وتماسك الأسرة والإبداع الفكري .",
  'April-description': "على امتداد شهر رمضان المبارك، أصبحت المدينة التعليمية زاخرةً بأنشطة للتأمل الروحي، والتعلم، والتواصل، والحفاظ على التراث الثقافي، كما ترافقت إحدى تقاليد المؤسسة في الشهر الفضيل مع مبادرة تركز على الاستدامة.",
  'May-description': "تجسيدًا لإيمانها بالدور الحيوي للتعليم في إطلاق القدرات وإتاحة الفرص، احتفت مؤسسة قطر بدفعة جديدة من خريجيها في حفل تخرّج لم يخل من التقاليد السنوية الراسخة، والذي مثّل أيضًا فرصة لتجديد اللقاء بين خريجي المؤسسة من مختلف الدفعات في المدينة التعليمية.",
  'June-description': "في يونيو، سادت روح التعاون المشهد التعليمي في مؤسسة قطر وعمَّت أجواء الاحتفاء بالإنجازات، فيما عززت المنظومة المعرفية الفريدة للمؤسسة الحوار ودعائم التقدم في مجال الذكاء الاصطناعي.",
  'July-description': "حافظت منظومة مؤسسة قطر على زخمها وحيوية أجوائها طوال أشهر الصيف عبر رواية القصص والإلهام بالفن وتسلق الجبال وممارسة الاستكشاف وتعزيز فرص التدريب ثم الاستعداد لبدء العام الجديد.",
  'September-description': "حققت مؤسسة قطر إنجازات بارزة في مساعيها للنهوض بالابتكار العربي والتعليم العالي، فيما عززت المؤسسة التزامها برعاية المعلمين مع بداية العام الدراسي الجديد.",
  'October-description': "في أكتوبر، تداعى مجتمع مؤسسة قطر للوقوف مع أهل غزة وشعب فلسطين في الشدّة التي يمرون بها. وتحولت المدينة التعليمية إلى ساحة للحوار حول القضايا العالمية وعززت مهارات الاكتشاف من خلال الفن وفتحت آفاقًا جديدة أمام النساء والفتيات في مجال الرياضة.",
  'November-description': "كانت مؤسسة قطر في نوفمبر منصة لحوار عالمي حول إمكانات الذكاء الاصطناعي واحتمالية أن يزعزع المشهد التعليمي العالمي ويحدث به تغييرات جذرية ، فيما يعمل في الوقت ذاته على في إزالة حواجز التعلم وإيصال صوت الشباب حول القضايا العالمية الكبرى.",
  'December-description': "مع اقتراب عام 2023 من نهايته، نجح طلاب مؤسسة قطر في توظيف الرياضة لدعم نضال الشعب الفلسطيني، فيما رعت مؤسسة قطر الحوارات وأقامت الشراكات وعززت المعرفة، بداية بالمجالات الجيوسياسية وحتى مجالات الصحة والتعليم.",
  'about_year_in_review_content_2023': 'استحضارًا لالتزام مؤسسة قطر بقضايا الابتكار وتيسير الوصول والاستدامة، يأتي التقرير التفاعلي لمؤسسة قطر، "استعراض عام"، ليسلط الضوء على تجليات 12 شهرًا في حياة المؤسسة ومنظومتها الفريدة من نوعها.\n\n  يرصد تقرير "مؤسسة قطر 2023: استعراض عام"، بشكل يراعي التسلسل الزمني، الإنجازات والمبادرات والمحطات البارزة ومعالم التأثير في التعليم والبحوث والابتكار وتنمية المجتمع التي ميّزت السنة السالفة الذكر، مستعينًا في ذلك بعدة رسومات بيانية ومنشورات وسائل التواصل الاجتماعي وعناصر متعددة الوسائط لجعل هذه الأحداث والفعاليات تنبض بالحياة.\n\n  نتمنى أن تستمتعوا بقراءة تقرير "مؤسسة قطر 2023: استعراض عام"، وإذا كنتم ترغبون في معرفة المزيد عن مؤسسة قطر، فإننا ندعوكم إلى زيارة موقعنا الإلكتروني:',
};