module.exports = {
  'selectLanguage': 'Select your language',
  headerlogotitle: 'Qatar Foundation Homepage',
  headerlogoarialabel: 'Qatar Foundation Homepage',
  QFlogomenubartitle: 'Qatar Foundation Logo',
  QFlogomenubararialabel: 'Qatar Foundation Logo',
  qf_text: 'Qatar Foundation',
  annual_report:  "THE YEAR IN REVIEW",
  annual_report_capitalize:  "The Year In Review",
  Intro: "Intro",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  july_august: "July/August",
  'Next chapter': "Next chapter",
  'Next': "Next",
  darkmode: 'Dark mode',
  lightmode: 'Light mode',
  'About the annual report': 'About the year in review',
  'Listen to the report': 'Listen to the report',
  'Turn off audio': 'Turn off audio',
  'Accessibility Widget': 'Accessibility Widget',
  'about_year_in_review': 'ABOUT THE YEAR IN REVIEW',
  'about_year_in_review_content': 'Reflecting Qatar Foundation’s commitment to innovation, accessibility, and sustainability, its Year in Review is an interactive, immersive account of 12 months in the life of the organization and its unique ecosystem. \n\nChronicling a year of achievements, initiatives, milestones, and impact in education, research and innovation, and community development, Qatar Foundation 2022: The Year in Review allows a story to unfold in chronological fashion – and brings it to life through infographics, social media posts, and multimedia elements. \n\nWe hope you enjoy reading Qatar Foundation 2022: The Year in Review – and if you’d like to find out more about QF, please visit ',
  'Scroll to explore': 'Scroll to explore',
  'Scroll to read full report': 'Scroll to read full report',
  'explore themes': 'Or explore by theme',
  'Themes': 'Themes',
  'Sustainability': 'Sustainability',
  'Progressive Education': 'Progressive Education',
  'Artificial Intelligence': 'Artificial Intelligence',
  'Precision Health': 'Precision Health',
  'sustainability': 'Sustainability',
  'Social Progress': 'Social Progress',
  'progressive-education': 'Progressive Education',
  'artificial-intelligence': 'Artificial Intelligence',
  'precision-health': 'Precision Health',
  'social-progress': 'Social Progress',
  'January-description': "A new year at Qatar Foundation meant new opportunities for learning, innovation, discovery, and collaboration – within a landscape of knowledge that has no global parallel.",
  'February-description': "The QF community showed its sense of compassion in a time of need for millions of people – as QF advanced inclusive sporting opportunities, and took learning beyond the classroom and into the city.",
  'March-description': "From elevating the profile of the Arabic language to advancing solutions for sustainability, QF illustrated its capacity to inspire and showcase ideas.",
  'April-description': "Reflection, learning, interaction, and embracing cultural heritage were at the heart of life at Education City throughout the Ramadan – where one of the traditions of the Holy Month came with a sustainable focus.",
  'May-description': "Symbolizing QF’s belief in what education makes possible, its latest batch of graduates were celebrated in traditional style – as alumni from across the years reunited at Education City.",
  'June-description': "QF’s education landscape saw celebration and collaboration – while its ecosystem fostered dialogue and development in the field of Artificial Intelligence.",
  'July-description': "Empowering people through stories, art, sports, and discovery, and opportunities for learning, QF’s ecosystem was alive with activity throughout the summer.",
  'September-description': "QF marked milestones in advancing Arab innovation, and in higher education – and reinforced its commitment to nurturing teachers as a new school year got under way.",
  'October-description': "The QF community came together for the people of Gaza and Palestine in their time of need – while Education City was the setting for dialogue on global issues, discovery through art, and the opening of a new chapter in women’s and girls’ sports.",
  'November-description': "QF was the stage for a global conversation on the potential of Artificial Intelligence to disrupt and transform education, while breaking down barriers to learning and giving youth a voice on the world’s key issues.",
  'December-description': "With 2023 drawing to a close, QF students used the power of sports to support the people of Palestine – while, from geopolitics to health and education, QF fostered dialogue, forged partnerships, and furthered knowledge.",
  'about_year_in_review_content_2023': 'Reflecting Qatar Foundation’s commitment to innovation, accessibility, and sustainability, its Year in Review is an interactive, immersive account of 12 months in the life of the organization and its unique ecosystem. \n\nChronicling a year of achievements, initiatives, milestones, and impact in education, research and innovation, and community development, Qatar Foundation 2023: The Year in Review allows a story to unfold in chronological fashion – and brings it to life through infographics, social media posts, and multimedia elements. \n\nWe hope you enjoy reading Qatar Foundation 2023: The Year in Review – and if you’d like to find out more about QF, please visit ',
};